<template>
    <div>
        <v-layout row justify-center>
            <v-dialog v-model="dialog" persistent max-width="490">
                <v-card >
                    <v-card-title class="headline">Möchtes du wirklich<span style="color:orangered;"> {{countAktivePersons()}}</span> {{(settings.herkunft == 3 ? settings.name : 'Personen' )}} auf
                    {{(settings.isAktiv ? 'Aktiv' : 'Inaktiv')}} setzen?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark @click="acceptDialog();">JA</v-btn>
                      <v-btn color="black" outlined dark @click="updateDialog(false)">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                personid: null

            }
        },
        watch: {

        },
        beforeMount() {

        },
        computed: {

        },
        props: ['dialog', 'accept', 'personen' , 'settings'],
        mounted() {
        },
        methods: {
            updateDialog: function (value) {
                this.$emit('update:dialog', value)
            },
            countAktivePersons: function () {
                if (this.settings.herkunft == 3) {
                    var toCount = this.personen.filter(function (element) { return element.isParticipant == true });
                }
                else {
                      var toCount = this.personen;
                }

                return toCount.length;
            },
            acceptDialog: function () {
                this.$emit('accept');
                this.$emit('update:dialog', false);
            },
            deniedDialog: function (value) {
                this.$emit('update:accept', true);
            },
        },

    }
</script>

<style scoped>
</style>
